<template>
  <div class="d-flex">
    <v-sheet class="text-center text-justify-center" :color="myBackgroundColour" :style="'color:' + myTextColour"><pre class="pa-2"> {{myTextColour}} </pre></v-sheet>
    <v-menu :close-on-content-click="false" transition="scale-transition" offset-x>
      <template v-slot:activator="{ on }">
        <v-btn color="info" v-on="on" dark class="ml-3">{{text}}</v-btn>
      </template>
      <v-color-picker v-model="myTextColour"></v-color-picker>
    </v-menu>                      
    <v-menu :close-on-content-click="false" transition="scale-transition" offset-x>
      <template v-slot:activator="{ on }">
        <v-btn color="info" v-on="on" dark class="ml-3">{{background}}</v-btn>
      </template>
      <v-color-picker v-model="myBackgroundColour"></v-color-picker>
    </v-menu>                      
  </div>
</template>

<script>
export default {

  props: {
    backgroundColour: null,
    textColour: null,
  },

  data () {
    return {
      myBackgroundColour: null,
      myTextColour: null,
    }
  },

  computed: {
    text () {
      if (this.$vuetify.breakpoint.smAndUp) return 'Set Text Colour';
      return 'Text';
    },
    background () {
      if (this.$vuetify.breakpoint.smAndUp) return 'Set Background Colour';
      return 'Backgrnd';
    }
  },

  watch: {
    myBackgroundColour: function () {
      this.$emit('selectColours', this.myBackgroundColour, this.myTextColour);
    },
    myTextColour: function () {
      this.$emit('selectColours', this.myBackgroundColour, this.myTextColour);
    }
  },

  created() {
    if (this.textColour) {
      this.myTextColour = this.textColour;
    } else {
      this.myTextColour="#000000FF";
    }
    if (this.backgroundColour) {
      this.myBackgroundColour = this.backgroundColour;
    } else {
      this.myBackgroundColour="#FFFFFFFF";
    }
  },
}
</script>

